<!-- DIALOG FINAL QUE SE ABRE UNA VEZ SE HA REALIZADO LA TRANSACCIÓN -->

<template>
  <q-dialog
    v-model="dialogOption"
    transition-show="slide-up"
    transition-hide="slide-down"
    class="q-pa-md justify-center"
    no-backdrop-dismiss
    persistent
  >
    <q-card :style="{ 'font-family': settings.typography }" class="border-white">
      <q-card-section class="q-pa-md text-weight-medium">
        <q-item-label class="text-h6 q-pl-none text-center">
          <q-btn
            icon="done"
            color="green"
            size="25px"
            flat
            style="border: none; box-shadow: none"
          />
          {{ $t("BuyingProcess.transactions.transaction-exchange") }}
        </q-item-label>
      </q-card-section>

      <q-card-section class="q-pl-sm q-pa-md text-weight-medium">
        {{ $t("BuyingProcess.products.my-transaction") }}
        <span class="text-weight-bold">{{ product.name }}</span>
        {{ $t("BuyingProcess.products.exchanged-successfully") }}
        <br />
        {{ $t("BuyingProcess.products.will-get-email") }}
      </q-card-section>

      <q-card-section class="column">
        <q-btn
          to="/transacciones"
          flat
          color="white"
          :label="$t('BuyingProcess.transactions.manage-order')"
          :style="{ 'background-color': settings.color_accent }"
        />
        <q-btn
          @click="reloadPage()"
          flat
          color="white"
          :label="$t('BuyingProcess.transactions.continue-buying')"
          :style="{ 'background-color': settings.color_primary }"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { defineAsyncComponent } from "vue";
import settings from "@/mixins/settings";

const CloseButton = defineAsyncComponent(() =>
  import("../../shared/buttons/CloseButton.vue")
);

export default {
  mixins: [settings],

  components: {
    CloseButton,
  },
  props: {
    dialogOption: {
      default: false,
    },
    product: {
      type: Object,
    },
  },

  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    reloadPage() {
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.q-card {
  border-radius: 25px 25px 25px 0px;
}

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    border-radius: 25px 25px 25px 0px !important;
  }
}

.q-dialog__inner--maximized > div {
  border-radius: 25px 25px 25px 0px !important;
}

.q-btn {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  text-transform: capitalize;
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-top: 10px;
}
</style>
